<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-body">
        <div class="d-flex">
          <!--begin: Info-->
          <div class="flex-grow-1">
            <!--begin: Title-->
            <div class="d-flex align-items-center justify-content-between flex-wrap">
              <div class="mr-3">
                <!--begin::Name-->
                <span class="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">{{ fichaLocalVotacion.nombre }} <i class="flaticon2-correct text-success icon-md ml-2"></i></span>
                <!--end::Name-->
                <div class="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">
                  {{ fichaLocalVotacion.direccion }}
                </div>
                <!--begin::Contacts-->
                <div class="d-flex flex-wrap my-2">
                  <span class="text-muted text-hover-primary font-weight-bold">{{ fichaLocalVotacion.regionNombre }} / {{ fichaLocalVotacion.subRegionNombre }}</span>
                </div>
              </div>
              <div class="my-lg-0 my-1">
                <a href="#" class="btn btn-sm btn-light-primary font-weight-bolder  mr-3" @click.prevent="imagenes.mostrarModal = true"><i class="fa fa-image"></i> Cargar imágenes</a>
                <a href="#" class="btn btn-sm btn-light-warning font-weight-bold" @click.prevent="toggleExpandirTurnos()" v-b-tooltip.hover :title="todosTurnosExpandidos ? 'Contraer todos los turnos' : 'Expandir todos los turnos'"><i class="fa p-0" :class="[todosTurnosExpandidos ? 'fa-compress' : 'fa-expand']"></i></a>
                <!--<a href="#" class="btn btn-sm btn-info font-weight-bolder text-uppercase">New Task</a>-->
              </div>
            </div>
            <!--end: Title-->
          </div>
          <!--end: Info-->
        </div>
        <div class="separator separator-solid my-7"></div>
     
      </div>
    </div>

    <ficha-turno-1 v-if="fichaLocalVotacion.puedeVisualizarTurno1 || fichaLocalVotacion.puedeEditarTurno1" :local-votacion-id="localVotacionId" :proceso-electoral-id="procesoElectoralId" ref="fichaTurno1Ref" :expandirContenido.sync="expandirContenidoTurno1" :intervalo-auto-guardado="5000"></ficha-turno-1>
    <div class="separator mt-2" v-if="fichaLocalVotacion.puedeVisualizarTurno1 || fichaLocalVotacion.puedeEditarTurno1"></div>
    <ficha-turno-2 v-if="fichaLocalVotacion.puedeVisualizarTurno2 || fichaLocalVotacion.puedeEditarTurno2" :local-votacion-id="localVotacionId" :proceso-electoral-id="procesoElectoralId" ref="fichaTurno2Ref" :expandirContenido.sync="expandirContenidoTurno2" :intervalo-auto-guardado="5000"></ficha-turno-2>
    <div class="separator mt-2" v-if="fichaLocalVotacion.puedeVisualizarTurno2 || fichaLocalVotacion.puedeEditarTurno2"></div>
    <ficha-turno-3 v-if="fichaLocalVotacion.puedeVisualizarTurno3 || fichaLocalVotacion.puedeEditarTurno3" :local-votacion-id="localVotacionId" :proceso-electoral-id="procesoElectoralId" ref="fichaTurno3Ref" :expandirContenido.sync="expandirContenidoTurno3" :intervalo-auto-guardado="5000"></ficha-turno-3>
    <div class="separator mt-2" v-if="fichaLocalVotacion.puedeVisualizarTurno3 || fichaLocalVotacion.puedeEditarTurno3"></div>
    <ficha-turno-4 v-if="fichaLocalVotacion.puedeVisualizarTurno4 || fichaLocalVotacion.puedeEditarTurno4" :local-votacion-id="localVotacionId" :proceso-electoral-id="procesoElectoralId" ref="fichaTurno4Ref" :expandirContenido.sync="expandirContenidoTurno4" :intervalo-auto-guardado="null"></ficha-turno-4>
    <div class="separator mt-2" v-if="fichaLocalVotacion.puedeVisualizarTurno4 || fichaLocalVotacion.puedeEditarTurno4"></div>
   
    <!--Modal AddEditProcesoElectoralMultimediaAlbun-->
    <div v-if="imagenes.mostrarModal">
      <add-edit-proceso-electoral-multimedia-album v-model="imagenes.mostrarModal" :local-votacion-id="localVotacionId"></add-edit-proceso-electoral-multimedia-album>
    </div>

    <fab-registro-incidente :local-votacion-id="localVotacionId" :proceso-electoral-id="procesoElectoralId" ref="fabRegistroIncidenteRef" @incidente-registrado="handleIncidenteSaved" @acto-violencia-registrado="handleActosViolenciaSaved"></fab-registro-incidente>
    <!--Seccion Modals-->
    <!--Modal Actos Violencia-->
    <div>
      <b-modal id="mdl-Acto-Violencia" size="lg" hide-footer>
        <template #modal-title> <i class="fa fa-hand-paper"></i> Actos de Violencia </template>
        <div class="row">
          <div class="col-md-12" v-if="actosViolencia.length > 0">
            <div v-for="acto in actosViolencia" :key="acto.localVotacionFichaActosViolenciaId" class="mt-2">
              <div class="row">
                <div class="col-md-8">
                  <h3 class="mt-0 mb-1 bg-light p-1">
                    <strong class="font-weight-bold"><i class="fa fa-clock fa-1x"></i> {{ acto.horaEventoActo | time }}</strong>
                  </h3>
                  <div class="ml-2">
                    <h6><i class="fas fa-th-list" style="font-size:88%;"></i> Descripción de lo ocurrido</h6>
                    <p class="pl-5">{{ acto.descripcionActo }}</p>
                    <h6 class="mt-1"><i class="fa fa-check" style="font-size:88%;"></i> Medidas tomadas</h6>
                    <p class="pl-5">{{ acto.medidasTomadasActo }}</p>
                  </div>
                </div>
                <div class="col-md-3">
                  <CoolLightBox :items="acto.coolboxImages" :index="acto.coolboxIndex" :useZoomBar="true" :slideshow="true" @close="acto.coolboxIndex = null"> </CoolLightBox>
                  <div class="symbol-group symbol-hover" v-if="acto.symbolImages.length > 0">
                    <div v-for="(imagen, imageIndex) in acto.symbolImages" :key="imagen.procesoElectoralMultimediaImagenId" @click="acto.coolboxIndex = imageIndex">
                      <div class="symbol symbol-50">
                        <img alt="" :src="apiResource(imagen.rutaArchivo)" />
                      </div>
                    </div>
                    <div class="symbol symbol-50 symbol-circle symbol-light" v-if="acto.masImagenes">
                      <span class="symbol-label font-weight-bold" @click="acto.coolboxIndex = 0">{{ acto.masImagenes }}+</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div v-else class="col-md-12">
            <div class="bg-light-primary p-3"><i class="fa fa-info-circle text-primary"></i> Sin registros</div>
          </div>
        </div>
      </b-modal>
    </div>

    <!--Modal Incidentes-->
    <div>
      <b-modal id="mdl-Incidente-Otro" size="lg" hide-footer>
        <template #modal-title><i class="fa fa-bell"></i> Incidentes (otros)</template>
        <div class="row">
          <div class="col-md-12" v-if="incidentes.length > 0">
            <div v-for="incidente in incidentes" :key="incidente.localVotacionFichaIncidenteId" class="mt-2">
              <div class="row">
                <div class="col-md-8">
                  <h3 class="mt-0 mb-1 bg-light p-1">
                    <strong class="font-weight-bold"><i class="fa fa-clock fa-1x"></i> {{ incidente.horaEventoActo | time }}</strong>
                  </h3>
                  <div class="ml-2">
                    <h6><i class="fas fa-th-list" style="font-size:88%;"></i> Descripción de lo ocurrido</h6>
                    <p class="pl-5">{{ incidente.descripcionActo }}</p>
                    <h6 class="mt-1"><i class="fa fa-check" style="font-size:88%;"></i> Autoridades que intervinieron</h6>
                    <p class="pl-5">{{ incidente.autoridadIntervencion }}</p>
                    <h6 class="mt-1"><i class="fa fa-eyedropper" style="font-size:88%;"></i> Tipo de incidente</h6>
                    <p class="pl-5">{{ incidente.tipoIncidenteParamDescripcion }}</p>
                    <h6 class="mt-1"><i class="fa fa-table" style="font-size:88%;"></i> Nro. de mesa</h6>
                    <p class="pl-5">{{ incidente.numeroMesa ? incidente.numeroMesa : "- no especificado -" }}</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <CoolLightBox :items="incidente.coolboxImages" :index="incidente.coolboxIndex" :useZoomBar="true" :slideshow="true" @close="incidente.coolboxIndex = null"> </CoolLightBox>
                  <div class="symbol-group symbol-hover" v-if="incidente.symbolImages.length > 0">
                    <div v-for="(imagen, imageIndex) in incidente.symbolImages" :key="imagen.procesoElectoralMultimediaImagenId" @click="incidente.coolboxIndex = imageIndex">
                      <div class="symbol symbol-60">
                        <img alt="" :src="apiResource(imagen.rutaArchivo)" />
                      </div>
                    </div>
                    <div class="symbol symbol-60 symbol-circle symbol-light" v-if="incidente.masImagenes">
                      <span class="symbol-label font-weight-bold" @click="incidente.coolboxIndex = 0">{{ incidente.masImagenes }}+</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div v-else class="col-md-12">
            <div class="p-3 bg-light-primary"><i class="fa fa-info-circle text-primary"></i> Sin registros</div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
.table-hover tbody tr:hover {
  background-color: #f9f9f9;
}
</style>

<style>
.with-status-indicator {
  position: relative;
}

.status-indicator {
  position: absolute;
  right: -10px;
  top: 10px;
}

body, html{
  font-size:16px !important;
}

.gutter-b{
  margin-bottom:5px;
}

.container {
  padding-top:5px !important;
  padding-bottom:5px !important;
  padding-left:5px !important;
  padding-right:5px !important;
}
.card-header {
    padding: 2rem 1.25rem;
}

.card.card-custom>.card-body {
    padding: 0rem 1.25rem;
    padding-top: 0rem;
    padding-right: 1.25rem;
    padding-bottom: 0rem;
    padding-left: 1.25rem;
}

</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import fichaTurno1 from "@/components/sheet/ficha-turno-1";
import fichaTurno2 from "@/components/sheet/ficha-turno-2";
import fichaTurno3 from "@/components/sheet/ficha-turno-3";
import fichaTurno4 from "@/components/sheet/ficha-turno-4";

import addEditProcesoElectoralMultimediaAlbum from "@/components/multimedia/add-edit-proceso-electoral-multimedia-album";
import Swal from "sweetalert2";

import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

/*intervalos de tiempo de Inactividad*/
import { INACTIVE_USER_TIME_THRESHOLD, USER_ACTIVITY_THROTTLER_TIME } from "@/helpers/constants.js";

export default {
  name: "edit-ficha-local",
  components: {
    fichaTurno1,
    fichaTurno2,
    fichaTurno3,
    fichaTurno4,
    addEditProcesoElectoralMultimediaAlbum,
    CoolLightBox,
  },
  props: {
    localVotacionId: {
      type: Number,
    },
    procesoElectoralId: {
      type: Number,
    },
    turno: {
      type: Number,
    },
  },
  data: () => ({
    imagenes: {
      mostrarModal: false,
    },
    expandirContenidoTurno1: false,
    expandirContenidoTurno2: false,
    expandirContenidoTurno3: false,
    expandirContenidoTurno4: false,
    //expandirContenidoTurno5: false,
    fichaGroupKey: "",
    fichaLocalVotacion: {
      //datosVarios
    },
    filterFicha: {
      localVotacionId: null,
      procesoElectoralId: null,
    },
    actosViolencia: [],
    incidentes: [],
    /*variables de inactividad*/
    isInactive: false,
    userActivityThrottlerTimeout: null,
    userActivityTimeout: null,
  }),
  computed: {
    todosTurnosExpandidos: function() {
      return (
        this.expandirContenidoTurno1 && this.expandirContenidoTurno2 && this.expandirContenidoTurno3 && this.expandirContenidoTurno4
        //&& this.expandirContenidoTurno5
      );
    },
  },
  methods: {
    onFichaUpdated: function(params) {
      switch (params.turno) {
        case 1:
          this.$refs.fichaTurno1Ref.doCargarFicha();
          break;
        case 2:
          this.$refs.fichaTurno2Ref.doCargarFicha();
          break;
        case 3:
          this.$refs.fichaTurno3Ref.doCargarFicha();
          break;
        case 4:
          this.$refs.fichaTurno4Ref.doCargarFicha();
          break;
        // case 5:
        //   this.$refs.fichaTurno5Ref.doCargarFicha();
        //   break;
      }

      this.$bvToast.toast("Se están actualizando los datos.", {
        title: "Turno " + params.turno,
        autoHideDelay: 5000,
        variant: "info",
        appendToast: false,
        solid: true,
      });
    },
    toggleExpandirTurnos: function() {
      if (this.todosTurnosExpandidos) {
        this.expandirContenidoTurno1 = false;
        this.expandirContenidoTurno2 = false;
        this.expandirContenidoTurno3 = false;
        this.expandirContenidoTurno4 = false;
        //this.expandirContenidoTurno5 = false;
      } else {
        {
          this.expandirContenidoTurno1 = true;
          this.expandirContenidoTurno2 = true;
          this.expandirContenidoTurno3 = true;
          this.expandirContenidoTurno4 = true;
          //this.expandirContenidoTurno5 = true;
        }
      }
    },

    handleIncidenteSaved: function(data) {
      console.log("handleIncidenteSaved");
      console.log(data);
      this.fichaLocalVotacion.nroIncidentes = data.nroIncidentes;
      this.fetchIncidenteFicha();
    },
    handleActosViolenciaSaved: function(data) {
      console.log("handleActosViolenciaSaved");
      console.log(data);
      this.fichaLocalVotacion.nroActosDeViolencia = data.nroActosDeViolencia;
      this.fetchActoViolenciaFicha();
    },
    fetchLocalVotacon: function() {
      this.showLoading();
      var model = {
        localVotacionId: this.localVotacionId,
        procesoElectoralId: this.procesoElectoralId,
        turno: this.turno,
      };

      this.$http.post(this.apiRoute("Sheet", "GetFichaLocalVotacion"), model).then(function(response) {
        if (!this.procesarRespuestaErrorDefecto(response)) {
          this.fichaLocalVotacion = response.body.data;
          this.showLoading(false);
        }
      }, this.mostrarMensajeExcepcionVueResource);
    },
    fetchActoViolenciaFicha: function() {
      var model = {
        ...this.filterFicha,
      };
      this.$http.post(this.apiRoute("Incident", "ListActoViolenciaFichaSeguimiento"), model).then(function(response) {
        if (!this.procesarRespuestaErrorDefecto(response)) {
          var actosViolenciaTemp = response.body.data.items;
          var vm = this;
          if (actosViolenciaTemp.length > 0) {
            for (var a = 0; a < actosViolenciaTemp.length; a++) {
              actosViolenciaTemp[a].coolboxIndex = null;
              if (actosViolenciaTemp[a].imagenes != null && actosViolenciaTemp[a].imagenes.length > 0) {
                actosViolenciaTemp[a].coolboxImages = actosViolenciaTemp[a].imagenes.map(function(x) {
                  return {
                    description: actosViolenciaTemp[a].descripcionActo,
                    thumb: vm.apiResource(x.rutaArchivoThumb),
                    src: vm.apiResource(x.rutaArchivo),
                  };
                });
                actosViolenciaTemp[a].symbolImages = actosViolenciaTemp[a].imagenes.slice(0, 3);
                actosViolenciaTemp[a].masImagenes = actosViolenciaTemp[a].imagenes.length - actosViolenciaTemp[a].symbolImages.length;
              } else {
                actosViolenciaTemp[a].coolboxImages = [];
                actosViolenciaTemp[a].symbolImages = 0;
                actosViolenciaTemp[a].masImagenes = 0;
              }
            }
          }

          this.actosViolencia = actosViolenciaTemp;
        }
      }, this.mostrarMensajeExcepcionVueResource);
    },
    fetchIncidenteFicha: function() {
      var model = {
        ...this.filterFicha,
      };
      this.$http.post(this.apiRoute("Incident", "ListIncidenteFichaSeguimiento"), model).then(function(response) {
        if (!this.procesarRespuestaErrorDefecto(response)) {
          var incidentesTemp = response.body.data.items;
          var vm = this;
          if (incidentesTemp.length > 0) {
            for (var a = 0; a < incidentesTemp.length; a++) {
              incidentesTemp[a].coolboxIndex = null;
              if (incidentesTemp[a].imagenes != null && incidentesTemp[a].imagenes.length > 0) {
                incidentesTemp[a].coolboxImages = incidentesTemp[a].imagenes.map(function(x) {
                  return {
                    description: incidentesTemp[a].descripcionActo,
                    thumb: vm.apiResource(x.rutaArchivoThumb),
                    src: vm.apiResource(x.rutaArchivo),
                  };
                });
                incidentesTemp[a].symbolImages = incidentesTemp[a].imagenes.slice(0, 3);
                incidentesTemp[a].masImagenes = incidentesTemp[a].imagenes.length - incidentesTemp[a].symbolImages.length;
              } else {
                incidentesTemp[a].coolboxImages = [];
                incidentesTemp[a].symbolImages = 0;
                incidentesTemp[a].masImagenes = 0;
              }
            }
          }
          this.incidentes = response.body.data.items;
        }
      }, this.mostrarMensajeExcepcionVueResource);
    },
    doMdlActoViolencia: function() {
      this.$bvModal.show("mdl-Acto-Violencia");
    },
    doMdlIncidenteOtro: function() {
      this.$bvModal.show("mdl-Incidente-Otro");
    },
    /*Metodos para verificar la inactividad del usuario*/
    activateActivityTracker: function() {
      window.addEventListener("mousedown", this.userActivityThrottler);
      window.addEventListener("mousemove", this.userActivityThrottler);
      window.addEventListener("scroll", this.userActivityThrottler);
      window.addEventListener("keydown", this.userActivityThrottler);
      window.addEventListener("resize", this.userActivityThrottler);
      window.addEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    deactivateActivityTracker: function() {
      window.removeEventListener("mousedown", this.userActivityThrottler);
      window.removeEventListener("mousemove", this.userActivityThrottler);
      window.removeEventListener("scroll", this.userActivityThrottler);
      window.removeEventListener("keydown", this.userActivityThrottler);
      window.removeEventListener("resize", this.userActivityThrottler);
      window.removeEventListener("touchstart", this.userActivityThrottler); //para dispositivos moviles
    },
    resetUserActivityTimeout: function() {
      clearTimeout(this.userActivityTimeout);

      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler();
        this.inactiveUserAction();
      }, INACTIVE_USER_TIME_THRESHOLD); /*=> INACTIVE_USER_TIME_THRESHOLD */
    },
    userActivityThrottler: function() {
      if (this.isInactive) {
        this.isInactive = false;
      }

      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, USER_ACTIVITY_THROTTLER_TIME); /*=> USER_ACTIVITY_THROTTLER_TIME*/
      }
    },
    inactiveUserAction: function() {
      this.isInactive = true;

      Swal.fire({
        icon: "warning",
        title: "Sesión Expirada",
        text: "Su sesión ha expirado. Por favor vuelva a iniciar sesión.",
        confirmButtonText: `Iniciar Sesión`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deactivateActivityTracker();
          clearTimeout(this.userActivityTimeout);
          clearTimeout(this.userActivityThrottlerTimeout);
          this.$router.push({ name: "login" });
        }
      });
    },
  },
  watch: {},
  mounted: function() {
    var vm = this;
    //this.localVotacionId = this.$route.params.localVotacionId;
    this.fichaGroupKey = "FI-" + this.procesoElectoralId + "-" + this.localVotacionId;

    this.filterFicha.localVotacionId = this.localVotacionId;
    this.filterFicha.procesoElectoralId = this.procesoElectoralId;

    setTimeout(function() {
      vm.$notifyHub.subscribeFichaGroup(vm.fichaGroupKey);
      vm.$notifyHub.$on("ficha-updated", vm.onFichaUpdated);
    }, 1000);

    //connection.invoke("ProcessTask", { method: "cargando-datos" });
    //connection.invoke("ProcessTask2", { test1: "cargando-datos", test2: 4 });

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Ficha de Observación Electoral" }]);
    this.initParsley();
    this.fetchLocalVotacon();
    this.fetchActoViolenciaFicha();
    this.fetchIncidenteFicha();
    this.activateActivityTracker();

    this.expandirContenidoTurno1 = this.turno == 1;
    this.expandirContenidoTurno2 = this.turno == 2;
    this.expandirContenidoTurno3 = this.turno == 3;
    this.expandirContenidoTurno4 = this.turno == 4;
  },
  beforeDestroy() {
    // Notify the server we stopped watching the question
    this.$notifyHub.unsubscribeFichaGroup(this.fichaGroupKey);
    // Make sure to cleanup SignalR event handlers when removing the component
    this.$notifyHub.$off("ficha-updated", this.onFichaUpdated);
  },
  destoyed: function() {},
};
</script>
